<template>
    <div class="layout-service">

        <!--s: Service Menu-->
        <ServiceMenu v-if="!isPhone()"/>
        <!--e: Service Menu-->

        <div class="layout-service-content">
            <div class="service-box">
                <h1 class="title" v-if="!isPhone()"></h1>
               <h1 class="title" v-if="isPhone()">
               {{ $t('footer.privacyPolicy') }}
               <a href="javascript:" class="close" @click="onClose"></a>
               </h1>
                <div class="privacy-box">
                    <div class="privacy-text">
                        {{ $t('footer.privacy1') }}<br/><br/>
                        {{ $t('footer.privacy2') }}<br/><br/>
                        {{ $t('footer.privacy3') }}<br/><br/>
                        {{ $t('footer.privacy4') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy5') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy6') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy7') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy8') }}<br/><br/>
                        {{ $t('footer.privacy9') }}<br/><br/>
                        {{ $t('footer.privacy10') }}<br/>
                        {{ $t('footer.privacy11') }}<br/><br/>
                        {{ $t('footer.privacy12') }}<br/><br/>
                        {{ $t('footer.privacy13') }}<br/><br/>
                        {{ $t('footer.privacy14') }}<br/><br/>
                        {{ $t('footer.privacy15') }}<br/><br/>
                        {{ $t('footer.privacy16') }}<br/><br/>
                        {{ $t('footer.privacy17') }}<br/><br/>
                        {{ $t('footer.privacy18') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy19') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy20') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy21') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy22') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy23') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy24') }}<br/><br/>
                        {{ $t('footer.privacy25') }}<br/><br/>
                        {{ $t('footer.privacy26') }}<br/><br/>
                        {{ $t('footer.privacy27') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy28') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy29') }}<br/>
                        {{ $t('footer.privacy30') }}<br/>
                        {{ $t('footer.privacy31') }}<br/>
                        {{ $t('footer.privacy32') }}<br/>
                        {{ $t('footer.privacy33') }}<br/>
                        {{ $t('footer.privacy34') }}<br/>
                        {{ $t('footer.privacy35') }}<br/>
                        {{ $t('footer.privacy36') }}<br/>
                        {{ $t('footer.privacy37') }}<br/>
                        {{ $t('footer.privacy38') }}<br/>
                        {{ $t('footer.privacy39') }}<br/>
                        {{ $t('footer.privacy40') }}<br/>
                        {{ $t('footer.privacy41') }}<br/>
                        {{ $t('footer.privacy42') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy43') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy44') }}<br/><br/>
                        {{ $t('footer.privacy45') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy46') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy47') }}<br/>
                        {{ $t('footer.privacy48') }}<br/>
                        {{ $t('footer.privacy49') }}<br/><br/>
                        {{ $t('footer.privacy50') }}<br/><br/>
                        {{ $t('footer.privacy51') }}<br/>
                        {{ $t('footer.privacy52') }}<br/>
                        {{ $t('footer.privacy53') }}<br/><br/>
                        {{ $t('footer.privacy54') }}<br/>
                        {{ $t('footer.privacy55') }}<br/>
                        {{ $t('footer.privacy56') }}<br/><br/>
                        {{ $t('footer.privacy57') }}<br/>
                        {{ $t('footer.privacy58') }}<br/><br/>
                        {{ $t('footer.privacy59') }}<br/>
                        {{ $t('footer.privacy60') }}<br/><br/>
                        {{ $t('footer.privacy61') }}<br/>
                        {{ $t('footer.privacy62') }}<br/><br/>
                        {{ $t('footer.privacy63') }}<br/>
                        {{ $t('footer.privacy64') }}<br/>
                        {{ $t('footer.privacy65') }}<br/><br/>
                        {{ $t('footer.privacy66') }}<br/>
                        {{ $t('footer.privacy67') }}<br/><br/>
                        {{ $t('footer.privacy68') }}<br/>
                        {{ $t('footer.privacy69') }}<br/><br/>
                        {{ $t('footer.privacy70') }}<br/>
                        {{ $t('footer.privacy71') }}<br/><br/>
                        {{ $t('footer.privacy72') }}<br/><br/>
                        {{ $t('footer.privacy73') }}<br/><br/>
                        {{ $t('footer.privacy74') }}<br/><br/>
                        {{ $t('footer.privacy75') }}<br/>
                        {{ $t('footer.privacy76') }}<br/><br/>
                        {{ $t('footer.privacy77') }}<br/>
                        {{ $t('footer.privacy78') }}<br/><br/>
                        {{ $t('footer.privacy79') }}<br/>
                        {{ $t('footer.privacy80') }}<br/><br/>
                        {{ $t('footer.privacy81') }}<br/><br/>
                        {{ $t('footer.privacy82') }}<br/>
                        {{ $t('footer.privacy83') }}<br/><br/>
                        {{ $t('footer.privacy84') }}<br/>
                        {{ $t('footer.privacy85') }}<br/><br/>
                        {{ $t('footer.privacy86') }}<br/>
                        {{ $t('footer.privacy87') }}<br/><br/>
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy88') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy89') }}<br/><br/>
                        {{ $t('footer.privacy90') }}<br/><br/>
                        {{ $t('footer.privacy91') }}<br/><br/>
                        {{ $t('footer.privacy92') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy93') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy94') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy95') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy96') }}<br/><br/>
                        {{ $t('footer.privacy97') }}<br/><br/>
                        {{ $t('footer.privacy98') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy99') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy100') }}<br/><br/>
                        {{ $t('footer.privacy101') }}<br/>
                        {{ $t('footer.privacy102') }}<br/>
                        {{ $t('footer.privacy103') }}<br/>
                        {{ $t('footer.privacy104') }}<br/>
                        {{ $t('footer.privacy105') }}<br/><br/>
                        {{ $t('footer.privacy106') }}<br/>
                        {{ $t('footer.privacy107') }}<br/><br/>
                        {{ $t('footer.privacy108') }}<br/>
                        {{ $t('footer.privacy109') }}<br/><br/>
                        {{ $t('footer.privacy110') }}<br/>
                        {{ $t('footer.privacy111') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy112') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy113') }}<br/><br/>
                        {{ $t('footer.privacy114') }}<br/>
                        {{ $t('footer.privacy115') }}<br/><br/>
                        {{ $t('footer.privacy116') }}<br/>
                        {{ $t('footer.privacy117') }}<br/>
                        {{ $t('footer.privacy118') }}<br/>
                        {{ $t('footer.privacy119') }}<br/>
                        {{ $t('footer.privacy120') }}<br/>
                        {{ $t('footer.privacy121') }}<br/>
                        {{ $t('footer.privacy122') }}<br/>
                        {{ $t('footer.privacy123') }}<br/><br/>
                        {{ $t('footer.privacy124') }}<br/><br/>
                        {{ $t('footer.privacy125') }}<br/>
                        {{ $t('footer.privacy126') }}<br/><br/>
                        {{ $t('footer.privacy127') }}<br/>
                        {{ $t('footer.privacy128') }}<br/><br/>
                        {{ $t('footer.privacy129') }}<br/><br/>
                        {{ $t('footer.privacy130') }}<br/><br/>
                        {{ $t('footer.privacy131') }}<br/><br/>
                        {{ $t('footer.privacy132') }}<br/><br/>
                        {{ $t('footer.privacy133') }}<br/><br/>
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy134') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy135') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy136') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy137') }}<br/><br/>
                        {{ $t('footer.privacy138') }}
                    </div>
                    <span class="privacy-title">{{ $t('footer.privacy139') }}</span>
                    <div class="privacy-text">
                        {{ $t('footer.privacy140') }}<br/><br/>
                        {{ $t('footer.privacy141') }}<br/><br/><br/><br/>
                        {{ $t('footer.privacy142') }}<br/><br/>
                        {{ $t('footer.privacy143') }}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import PrivacyPolicy from './privacyPolicy'
export default PrivacyPolicy
</script>
<style lang="less">
@import "privacyPolicy";
</style>
