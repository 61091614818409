/**
 * 服务左侧菜单
 * @type {{[]}}
 */
import { isPhone } from "@/utils/tools";
import { serviceMenu } from '@/service/public/enum'
const ServiceMenu = {
    name:'ServiceMenu',
    data() {
        return {
            isPhone,
            serviceMenu:serviceMenu,
            content:'/help-center',
        }
    },
    created() {
        this.content = this.$route.path
    },
    methods: {
        /**
         * 跳转
         * @param links
         */
        onLinks(links){
            this.$router.push(links)
        }
    },
    watch: {
        $route: {
            handler() {
                this.content = this.$route.path;
            },
            deep: true,
        }
    },
}
export default ServiceMenu
