import VueI18n from "@/language/index";
import tipsImg1 from "@/assets/Tips/tips-img1.svg";
import tipsImg2 from "@/assets/Tips/tips-img2.svg";
import tipsImg3 from "@/assets/Tips/tips-img3.svg";
import tipsImg4 from "@/assets/Tips/tips-img4.svg";
import tipsImg5 from "@/assets/Tips/tips-img5.svg";
import tipsImg6 from "@/assets/Tips/tips-img6.svg";
import tipsImg7 from "@/assets/Tips/tips-img7.svg";
import tipsImg8 from "@/assets/Tips/tips-img8.svg";
import tipsImg9 from "@/assets/Tips/tips-img9.svg";
import tipsImg10 from "@/assets/Tips/tips-img10.svg";
export const SOURCE_NAME = {
  MENU_LINK_LIST: "MENU_LINK_LIST", //header下拉菜单
  SUPER_SALE: "SUPER_SALE", //超卖
  MENU_ADVERT: "MENU_ADVERT" //广告
};

/**
 * 跳转的名称
 * @type {{}}
 */
export const LINKS_NAME = {
  LINKS_HOME_PAGE: "HOME_PAGE", //首页
  LINKS_LOGIN: "LINKS_LOGIN", //登录
  ACCOUNT_INFO: "ACCOUNT_INFO", //账户信息
  SEARCH_REMIND_SHOPPING_LIST: "SEARCH_REMIND_SHOPPING_LIST", //我的搜索/提醒/购物清单
  USER_PREFERENCES: "USER_PREFERENCES", //用户偏好
  USER_LOGOUT: "USER_LOGOUT" //退出登录
};

/**
 *  中文币种
 * @type {()[]}
 */
export const currency = [
  {
    name: "GBP £ ",
    code: "gbp"
  },
  {
    name: "USD $ ",
    code: "usd"
  },
  {
    name: "EUR € ",
    code: "eur"
  },
  {
    name: "CNY ¥ ",
    code: "cny"
  }
];

export const currencyCN = [
  {
    type: "title",
    title: "R"
  },
  {
    type: "content",
    name: "人民币"
  },
  {
    type: "title",
    title: "M"
  },
  {
    type: "content",
    name: "美元"
  }
];
/**
 *  英文币种
 * @type {()[]}
 */
export const currencyEn = [
  {
    type: "title",
    title: "R"
  },
  {
    type: "content",
    name: "RMB"
  },
  {
    type: "title",
    title: "U"
  },
  {
    type: "content",
    name: "U.S. dollar"
  }
];

/**
 * 语言
 * @type {*[]}
 */
export const language = [
  {
    code: "CN",
    name: "简体中文",
    languageId: 1
  },
  {
    code: "EN",
    name: "English",
    languageId: 2
  }
];

/**
 * 用户菜单
 * @type {()[]}
 */
export const userMenu = [
  {
    id: 1,
    label: LINKS_NAME.ACCOUNT_INFO,
    name: VueI18n.t("userCenter.accountInfo")
  },
  {
    id: 2,
    label: LINKS_NAME.SEARCH_REMIND_SHOPPING_LIST,
    name: VueI18n.t("userCenter.searchAlertsWishlist")
  },
  {
    id: 3,
    label: LINKS_NAME.USER_PREFERENCES,
    name: VueI18n.t("userCenter.preferences")
  },
  {
    id: 4,
    name: VueI18n.t("userCenter.orderHistory")
  },
  {
    id: 5,
    label: LINKS_NAME.USER_LOGOUT,
    name: VueI18n.t("userCenter.logout")
  }
];

/**
 * 随机提示数据
 * image 图片地址
 * text 文字描述
 * @type {{image: string, text: string}[]}
 */
export const tipsData = [
  {
    id: 1,
    image: tipsImg1,
    text: VueI18n.t("login.tips1")
  },
  {
    id: 2,
    image: tipsImg2,
    text: VueI18n.t("login.tips2")
  },
  {
    id: 3,
    image: tipsImg3,
    text: VueI18n.t("login.tips3")
  },
  {
    id: 4,
    image: tipsImg5,
    text: VueI18n.t("login.tips4")
  },
  {
    id: 5,
    image: tipsImg4,
    text: VueI18n.t("login.tips5")
  },
  {
    id: 6,
    image: tipsImg6,
    text: VueI18n.t("login.tips6")
  },
  {
    id: 7,
    image: tipsImg7,
    text: VueI18n.t("login.tips7")
  }
];

/**
 * 喜爱随机展示数据
 * @type {*[]}
 */
export const likeTipsData = [
  {
    image: tipsImg8,
    text: VueI18n.t("carousel.tipsText1")
  },
  {
    image: tipsImg9,
    text: VueI18n.t("carousel.tipsText2")
  }
];

/**
 * 用户中心提示
 * @type {*[]}
 */
export const userCenterTips = [
  {
    image: tipsImg7,
    title: "",
    text: ""
  },
  {
    image: tipsImg10,
    title: VueI18n.t("userCenter.alertsTitle"),
    text: VueI18n.t("userCenter.alertsText")
  },
  {
    image: tipsImg8,
    title: VueI18n.t("userCenter.wishlistTitle"),
    text: VueI18n.t("userCenter.wishlistText")
  }
];

/**
 * 订阅分类数据
 * @type {*[]}
 */
export const subscribeType = [
  {
    label: VueI18n.t("signup.women"),
    value: 1,
    checkBox: 0
  },
  {
    label: VueI18n.t("signup.men"),
    value: 2,
    checkBox: 0
  },
  {
    label: VueI18n.t("signup.kids"),
    value: 3,
    checkBox: 0
  },
  {
    label: VueI18n.t("signup.home"),
    value: 4,
    checkBox: 0
  }
];

/**
 * 订阅footer分类数据
 * @type {*[]}
 */
export const subscribeTypeFooter = [
  {
    label: VueI18n.t("signup.women"),
    value: 1,
    checkBox: 0
  },
  {
    label: VueI18n.t("signup.men"),
    value: 2,
    checkBox: 0
  },
  {
    label: VueI18n.t("signup.kids"),
    value: 3,
    checkBox: 0
  },
  {
    label: VueI18n.t("signup.home"),
    value: 4,
    checkBox: 0
  }
];

/**
 * 个人中心单选数据
 * @type {*[]}
 */
export const radioType = [
  {
    label: VueI18n.t("userCenter.yes"),
    value: 1
  },
  {
    label: VueI18n.t("userCenter.no"),
    value: 0
  }
];

/**
 * 菜单数据
 * @type {{}[]}
 */
export const menuType = [
  {
    id: "menu1",
    name: VueI18n.t("menu.category"),
    data: [],
    visible: false
  },
  {
    id: "menu2",
    name: VueI18n.t("menu.brands"),
    data: [],
    visible: false
  },
  {
    id: "menu3",
    name: VueI18n.t("menu.price"),
    visible: false
  }
];

/**
 * 排序数据集
 * @type {({})[]}
 */
export const sortBy = [
  {
    id: 1,
    name: VueI18n.t("list.newest")
  },
  {
    id: 2,
    name: VueI18n.t("list.trending")
  },
  {
    id: 3,
    name: VueI18n.t("list.priceLow")
  },
  {
    id: 4,
    name: VueI18n.t("list.priceHigh")
  },
  {
    id: 5,
    name: VueI18n.t("list.discountHigh")
  }
];

/**
 * 服务菜单
 * @type {()[]}
 */
export const serviceMenu = [
  {
    id: 1,
    label: "/help-center",
    name: VueI18n.t("footer.helpCentre")
  },
  {
    id: 2,
    label: "/contact-us",
    name: VueI18n.t("footer.contactUs")
  },
  {
    id: 3,
    label: "/about-us",
    name: VueI18n.t("footer.aboutUs")
  },
  {
    id: 4,
    label: "/accessibility",
    name: VueI18n.t("footer.accessibility")
  },
  {
    id: 5,
    label: "/terms-conditions",
    name: VueI18n.t("footer.termsConditions")
  },
  {
    id: 6,
    label: "/privacy-policy",
    name: VueI18n.t("footer.privacyPolicy")
  }
];

/**
 * 帮助中心数据
 * @type {{}[]}
 */
export const helpData = [
  {
    title: VueI18n.t("footer.help9"),
    text: VueI18n.t("footer.help10"),
    visible: false,
    id: 1
  },
  {
    title: VueI18n.t("footer.help11"),
    text: VueI18n.t("footer.help12"),
    visible: false,
    id: 2
  },
  {
    title: VueI18n.t("footer.help13"),
    text: VueI18n.t("footer.help14"),
    visible: false,
    id: 3
  },
  {
    title: VueI18n.t("footer.help15"),
    text: VueI18n.t("footer.help16"),
    visible: false,
    id: 4
  },
  {
    title: VueI18n.t("footer.help17"),
    text: VueI18n.t("footer.help18"),
    visible: false,
    id: 5
  },
  {
    title: VueI18n.t("footer.help19"),
    text: VueI18n.t("footer.help20"),
    visible: false,
    id: 6
  }
];
