<template>
    <!--s: Service Menu-->
    <div class="layout-service-left">
        <h1 class="title" v-if="!isPhone()">{{ $t("footer.service") }}</h1>
        <ul>
            <li :key="key" v-for="(menu,key) in serviceMenu">
                <a href="javascript:" :class="content === menu.label? 'active':''" @click="onLinks(menu.label)">{{ menu.name }}</a>
            </li>
        </ul>
    </div>
    <!--e: Service Menu-->
</template>
<script>
import ServiceMenu from './serviceMenu'
export default ServiceMenu
</script>
<style lang="less">
@import "serviceMenu";
</style>
